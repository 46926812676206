body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body > div {
  height: 100%;
}
body > div > aside {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #373737;
  color: #efefef;
}
body > div > aside a {
  color: #efefef;
  text-decoration: none;
}
body > div > section {
  float: left;
  width: 75%;
  height: 100%;
  background-color: #efefef;
  color: #373737;
}

nav .caret {
  display: inline-block;
  height: 0;
  width: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

nav > ul {
  margin: 0 auto;
  position: relative;
  width: 950px;
}

nav > ul li {
  display: block;
  padding: 14px 10px;
}

nav > ul li > a {
  outline: 0;
  padding: 14px 10px;
}

nav > ul li:hover {
  background-color: #000;
}

nav > ul > li:first-child {
  background-size: 30%;
  background-repeat: no-repeat;
  font-size: 1.3em;
  height: 38px;
  padding: 13px 13px 0 60px;
}

nav .dropdown ul {
  background-color: #2D2D2A;
  border-radius: 0 0 4px 4px;
  display: none;
  margin: 3px 0 0;
  position: absolute;
  left: 259px;
  top: 49px;
  width: 160px;
}

nav .dropdown ul li {
  padding: 3px 15px;
}

nav .dropdown ul li:last-child {
  border-radius: 0 0 4px 4px;
}

nav .dropdown ul li a {
  padding: 3px 15px;
}

nav .open ul {
  display: block;
}

